import React from 'react'
import { Form } from 'react-final-form'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FormField from '../components/FormField'
import {
  required,
  minLength,
  composeValidators,
} from '../components/FormField/validations'

import localization from '../../constants/localization'

import { LinearButton } from '../components/Button'

const FormLayout = ({ children }) => (
  <Row noGutters={true}>
    <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
      {children}
    </Col>
  </Row>
)

export default ({ language, onSubmit }) => {
  const customValidators = (...validators) => val =>
    composeValidators(validators, language, val)
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <FormField
                name="address"
                type="text"
                placeholder={localization.get(language, 'chequeAddress')}
                validate={customValidators(required)}
              />
              <FormField
                name="city"
                type="text"
                placeholder={localization.get(language, 'chequeCity')}
                validate={customValidators(required)}
              />
              <FormField
                name="province"
                type="text"
                placeholder={localization.get(language, 'chequeProvince')}
                validate={customValidators(required)}
              />
              <FormField
                name="postalCode"
                type="text"
                placeholder={localization.get(language, 'postalCode')}
                validate={customValidators(required, minLength(5))}
              />

              <div className="flex flex-col sm:flex-row items-center justify-even mt-8">
                <LinearButton
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  className="bg-blue-2 px-2 text-sm sm:text-base"
                  css={css`
                    min-width: 8.1rem;
                  `}
                >
                  {localization.get(language, 'cashOutNow')}
                </LinearButton>

                <span className="text-sm mt-4 sm:mt-0 sm:ml-8">
                  <Link className="text-blue-4 text-base" to={'/app/cashout'}>
                    {localization.get(language, 'back')}
                  </Link>
                </span>
              </div>
            </FormLayout>
          </form>
        )
      }}
    />
  )
}
