import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import Card from 'react-bootstrap/Card'

import localization from '../../constants/localization'
import { getLanguage, getAlerts } from '../../store/data'
import { getNewAlerts } from '../../actions/alerts'
import { HeaderLoginUser } from '../components/Header'
import alertIcon from '../../assets/images/alerts.svg'

const Alert = ({
  alerts: thisAlerts,
  language,
  getNewAlerts: getNewAlertsAction,
}) => {
  useEffect(() => {
    getNewAlertsAction()
  }, [])
  const { alerts } = thisAlerts.data

  return (
    <div>
      <HeaderLoginUser />
      <div className="flex justify-center py-12 px-4 sm:p-16">
        <Card
          className="flex items-center text-gray-3 p-6 sm:p-16 w-full md:w-3/4 lg:1/3 rounded-lg"
          css={css`
            max-width: 36rem;
          `}
        >
          <Card.Title className="self-start flex mb-4">
            <img src={alertIcon} alt="alert" />
            <span className="ml-4">{localization.get(language, 'alerts')}</span>
          </Card.Title>
          <Card.Body className="p-0">
            {alerts.map(alert => {
              return (
                <div
                  key={alert._id}
                  className="border-t py-4 font-light text-gray-5"
                >
                  {language === 'en' ? alert.message : alert.message_fr}
                  <span className=" ml-4 text-gray-2">
                    - {alert.updated_at.split('T').slice(0, 1)}
                  </span>
                </div>
              )
            })}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  // user: getUser(state),
  alerts: getAlerts(state),
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { getNewAlerts }
)(Alert)
