import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { Form } from 'react-final-form'
import FormField from '../components/FormField'
import {
  required,
  minLength,
  composeValidators,
} from '../components/FormField/validations'
import { CustomButton } from '../components/Button'

import localization from '../../constants/localization'

const ResetForm = ({ language, onSubmit, message, messageColor }) => {
  const customValidators = (...validators) => val =>
    composeValidators(validators, language, val)
  return (
    <Form
      className="z-50"
      onSubmit={onSubmit}
      validate={values => {
        const errors = {}
        if (!values.passwordConfirm) {
          errors.passwordConfirm = localization.get(language, 'required')
        } else if (values.passwordConfirm !== values.newPassword) {
          errors.passwordConfirm = localization.get(language, 'inputNotMatch')
        }
        return errors
      }}
      render={({ handleSubmit, submitting, hasValidationErrors, form }) => (
        <form
          onSubmit={async e => {
            try {
              await handleSubmit(e)
              await form.reset()
            } catch (err) {
              console.log(err)
            }
          }}
        >
          <div className="text-lg mb-2 ">
            {localization.get(language, 'resetPassword')}
          </div>
          <FormField
            name="newPassword"
            type="password"
            placeholder={localization.get(language, 'newPassword')}
            validate={customValidators(required, minLength(8))}
          />

          <FormField
            name="passwordConfirm"
            type="password"
            placeholder={localization.get(language, 'confirmPassword')}
            validate={customValidators(required)}
            message={message}
            messageColor={messageColor}
          />

          <div className="mt-4">
            <CustomButton
              type="submit"
              disabled={submitting || hasValidationErrors}
              className="bg-blue-2 h-12 px-8 text-base"
              css={css`
                min-width: 8.1rem;
              `}
              content={localization.get(language, 'changePassword')}
            />
          </div>
        </form>
      )}
    />
  )
}

export default ResetForm
