import React from 'react'
import { useState } from 'react'

import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { getLanguage } from '../../store/data'
import LogoWithSpan from '../components/LogoWithSpan'
import LoginForm from './LoginForm'
import bgImage from '../../assets/images/signup-page-bg.webp'
import { loginUser } from '../../actions/user'

import HowItWorks from '../components/HowItWork'
import InviteMeSection from '../components/InviteMeSection'
import FacebookLoginButton from '../components/FacebookLoginButton'
import { HeaderDefault } from '../components/Header'
import GlobalBg from '../components/GlobalBg'

export const LinkBreak = ({ language }) => (
  <div className="text-center my-6">
    <div className="h-3 mb-4 border-bottom border-brown-1 flex flex-col">
      <h1 className="h-12 w-1/12 block bg-white self-center text-gray-2">
        {language === 'en' ? 'Or' : 'Ou'}
      </h1>
    </div>
  </div>
)

const Login = ({ language, loginUser: loginUserAction }) => {
  const [message, setMessage] = useState('')
  const [messageColor, setMessageColor] = useState('')

  const onSubmit = values => {
    // login user
    loginUserAction({
      email: values.email,
      password: values.password,
      callback: onLoginCallback
    })
  }

  const onLoginCallback = (message, success) => {
    setMessage(message)
    setMessageColor(success ? 'success' : 'danger')
    setTimeout(() => {
      setMessage('')
    }, 2000)
  }

  return (
    <div className="pt-8">
      {/* global styles */}
      <GlobalBg bgImage={bgImage} />

      <HeaderDefault />

      <LogoWithSpan />

      <Row className="mt-10 mb-16" noGutters={true}>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 3, span: 6 }}
          xl={{ offset: 4, span: 4 }}
          className="bg-white py-12 px-4 sm:px-10 rounded-lg shadow"
        >
          <FacebookLoginButton isLogin={true} language={language} />

          {/* line break */}
          <LinkBreak language={language} />

          <LoginForm language={language} onSubmit={onSubmit} message={message} messageColor={messageColor} />
        </Col>
      </Row>

      <HowItWorks isLogin={true} language={language} />
      <InviteMeSection language={language} />
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { loginUser }
)(Login)
