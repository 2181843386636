import React from 'react'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Global, css } from '@emotion/core'

import { getLanguage, getUser } from '../../store/data'
import LogoWithSpan from '../components/LogoWithSpan'
import { contactItems } from '../components/Footer'
import localization from '../../constants/localization'
import chatIcon from '../../assets/images/chat-icon.svg'
import bgImage from '../../assets/images/contact-page-bg.jpg'
import { CustomButton } from '../components/Button'
import { HeaderDefault, HeaderLoginUser } from '../components/Header'
import { isUserLoggedIn } from '../../utils/user'
import GlobalBg from '../components/GlobalBg'

const Contact = ({ language, isLoggedIn }) => {
  return (
    <div>
      {/* global styles */}
      <GlobalBg bgImage={bgImage} />

      <div className="mb-10">
        {isLoggedIn ? <HeaderLoginUser /> : <HeaderDefault />}
      </div>

      <LogoWithSpan />

      <Row className="justify-content-md-center mt-10 mb-20" noGutters={true}>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 0, span: 4 }}
          lg={{ offset: 0, span: 3 }}
          className="bg-white py-12 px-10 rounded-lg my-1"
        >
          {contactItems.map((contactItem, contactItemIndex) => (
            <div
              key={contactItem.mail}
              className={contactItemIndex !== 0 ? 'pt-8' : ''}
            >
              <div className="text-blue-1 text-xl">
                {localization.get(language, contactItem.name)}
              </div>
              <a
                className="text-gray-2 text-sm"
                href={`mailto:${localization.get(language, contactItem.mail)}`}
              >
                {localization.get(language, contactItem.mail)}
              </a>
            </div>
          ))}
        </Col>

        {/* <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 0, span: 4 }}
          lg={{ offset: 0, span: 3 }}
          className="bg-white py-10 px-8 rounded-lg md:ml-6 my-1"
        >
          <div className="flex">
            <img src={chatIcon} alt="Chat" />
            <span className="text-blue-1 text-xl pl-2">
              {localization.get(language, 'chat')}
            </span>
          </div>
          <div className="text-gray-2 text-sm">
            {localization.get(language, 'talkToOurCustomerCupport')}
          </div>

          <CustomButton
            className="bg-blue-2 h-12 px-10 text-lg mt-8"
            onClick={() => console.log('chat')}
            content={localization.get(language, 'chat')}
          />
        </Col> */}
      </Row>
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  isLoggedIn: isUserLoggedIn(getUser(state)),
})

export default connect(
  mapStateToProps,
  {}
)(Contact)
