import React from 'react'
import { useState } from 'react'
import { FacebookProvider, LoginButton } from 'react-facebook'
import { connect } from 'react-redux'

import { CustomButton } from '../Button'
import localization from '../../../constants/localization'
import facebookLogo from '../../../assets/images/facebook-logo.svg'
import { facebookLogin } from '../../../actions/user'
import config from '../../../config'

const FacebookLoginButton = ({
  language,
  facebookLogin: facebookLoginAction,
  isLogin,
}) => {
  const [message, setMessage] = useState('')
  const [messageColor, setMessageColor] = useState('')

  const Message = ({ message, color = 'success' }) => {
    return (
      <span className={`text-sm ${message ? `h-12` : ''} text-${color} w-full flex items-center`}>{message}</span>
    )
  }

  const loginSpan = isLogin ? 'loginWithFb' : 'signupWithFb'
  return (
    <FacebookProvider appId={config.facebook_app_id}>
      <LoginButton
        scope="email"
        onCompleted={data =>
          facebookLoginAction({
            accessToken: data.tokenDetail.accessToken,
          })
        }
        onError={() => {
          setMessage(language === 'fr' ? 'Échec de la connexion à Facebook!' : 'Facebook login failed!')
          setMessageColor('danger')
          setTimeout(() => {
            setMessage('')
          }, 2000)
        }}
        className="w-full flex items-center justify-center"
      >
        <CustomButton
          type="button"
          className="w-full bg-blue-3 h-12 px-10 text-lg"
          as="div"
          content={
            <div className="flex h-full items-center justify-center leading-tight">
              <img src={facebookLogo} alt="Facebook" />
              <span className="pl-4 text-white text-base">
                {localization.get(language, loginSpan)}
              </span>
            </div>
          }
        />
      </LoginButton>
      <Message message={message} color={messageColor} />
    </FacebookProvider>
  )
}

export default connect(null, { facebookLogin })(FacebookLoginButton)
