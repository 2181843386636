import React from 'react'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'gatsby'

import localization from '../../constants/localization'

import { getUser, getLanguage } from '../../store/data'
import { HeaderLoginUser } from '../components/Header'
import eTransferIcon from '../../assets/images/e-transfer-icon.png'
import eTransferIconFr from '../../assets/images/e-transfer-icon-fr.png'

import arrowIcon from '../../assets/images/arrow-icon.svg'

const LinkBreak = () => <div className="my-8 border-bottom border-gray-6"></div>
const cashOutOptions = language => [
  {
    name: localization.get(language, 'cashOutByCheque'),
    icon: '',
    to: '/app/cashoutbycheque',
  },
  {
    name: localization.get(language, 'cashOutByETransfer'),
    icon: language === 'en' ? eTransferIcon : eTransferIconFr,
    to: '/app/cashoutbyemail',
  },
]

const CashOut = ({ user, language }) => {
  const cashOutBalance = user.data.user.balance
  return (
    <div>
      <HeaderLoginUser />
      <div className="flex justify-center p-8">
        <Card
          className="text-center text-gray-3 py-8 w-full md:w-3/4 lg:1/3 rounded-lg"
          css={css`
            max-width: 31rem;
          `}
        >
          <Card.Body className="flex flex-col text-lg font-medium items-center">
            <div>{localization.get(language, 'cashOut')}</div>
            <span className="text-base mt-8">
              {localization.get(language, 'amountToBeWithdraw')}
            </span>

            <div className="text-green-1">$ {cashOutBalance}</div>
            {/* <div>{localization.get(language, 'cashOut')}</div> */}
          </Card.Body>
          <LinkBreak />
          <Card.Body className="flex flex-col ">
            {cashOutOptions(language).map(option => (
              <Row
                key={option.to}
                className="flex mx-2 md:mx-12 py-4 border-b border-gray-400"
              >
                <Col
                  className="flex justify-start p-0 items-end "
                  xs={{ span: 10 }}
                >
                  <div>{option.name}</div>
                  {option.icon && (
                    <img
                      src={option.icon}
                      className="ml-2 w-1/4"
                      alt="e-transfer"
                    />
                  )}
                </Col>
                <Col
                  className="flex justify-end items-center p-0"
                  xs={{ span: 2 }}
                >
                  <Link to={option.to}>
                    <img src={arrowIcon} alt="next" />
                  </Link>
                </Col>
              </Row>
            ))}
            <Row className="flex  mx-2 md:mx-12  pt-12 " noGutters={true}>
              <span className="text-sm text-left">
                <Link className="text-blue-4 text-base" to={'/app/myaccount'}>
                  {localization.get(language, 'cancel')}
                </Link>
              </span>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  language: getLanguage(state),
})

export default connect(mapStateToProps, {})(CashOut)
