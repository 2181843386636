import React from 'react'
import { Form } from 'react-final-form'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import FormField from '../components/FormField'
import {
  required,
  minLength,
  validEmail,
  composeValidators,
} from '../components/FormField/validations'
import { CustomButton } from '../components/Button'
import localization from '../../constants/localization'

const SignupForm = ({ language, onSubmit, message, messageColor }) => {
  const customValidators = (...validators) => val =>
    composeValidators(validators, language, val)
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="email"
            type="text"
            placeholder={localization.get(language, 'email')}
            validate={customValidators(required, validEmail)}
          />

          <FormField
            name="password"
            type="password"
            placeholder={localization.get(language, 'passwordLogin')}
            validate={customValidators(required, minLength(8))}
            message={message}
            messageColor={messageColor}
          />

          <div className="mt-4">
            <Link
              className="text-blue-4 text-base font-medium"
              to="/app/forgot"
            >
              {localization.get(language, 'forgotPassword')}
            </Link>
          </div>

          <div className="flex items-center justify-between mt-4">
            <CustomButton
              type="submit"
              disabled={submitting || hasValidationErrors}
              className="bg-blue-2 h-12 px-8 text-base"
              css={css`
                min-width: 8.1rem;
              `}
              content={localization.get(language, 'login')}
            />

            <span className="text-sm text-right">
              <span>{localization.get(language, 'noAccountYet')}</span>
              <Link className="text-blue-4 ml-2" to="/app/signup">
                {localization.get(language, 'signup')}
              </Link>
            </span>
          </div>
        </form>
      )}
    />
  )
}

export default SignupForm
