import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Global, css } from '@emotion/core'

import { getLanguage } from '../../store/data'
import { resetPassword } from '../../actions/forgot'
import bgImage from '../../assets/images/signup-page-bg.webp'
import HowItWork from '../components/HowItWork'
import InviteMeSection from '../components/InviteMeSection'
import LogoWithSpan from '../components/LogoWithSpan'
import ResetForm from './ResetForm'
import { HeaderDefault } from '../components/Header'
import GlobalBg from '../components/GlobalBg'

import { navigate } from 'gatsby'

const ResetPassword = ({ language, resetPassword: resetPasswordAction }) => {
  const [message, setMessage] = useState('')
  const [messageColor, setMessageColor] = useState('')

  const onSubmit = values => {
    let token = window.location.href.split('pw=').pop()
    resetPasswordAction({
      password: values.newPassword,
      token: window.location.href.split('pw=')[1],
      callback: onResetPasswordCallback
    })
  }

  const onResetPasswordCallback = (message, success) => {
    setMessage(message)
    setMessageColor(success ? 'success' : 'danger')
    setTimeout(() => {
      setMessage('')
      if (success) {
        navigate('/app/login')
      }
    }, 2000)
  }

  return (
    <div className="pt-8">
      {/* global styles */}
      <GlobalBg bgImage={bgImage} />

      <HeaderDefault />
      <LogoWithSpan content="liveBetterAndSave" />

      <Row className="mt-10 mb-16" noGutters={true}>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 3, span: 6 }}
          xl={{ offset: 4, span: 4 }}
          className="bg-white py-12 px-10 rounded-lg"
        >
          <ResetForm language={language} onSubmit={onSubmit} message={message} messageColor={messageColor} />
        </Col>
      </Row>

      <HowItWork language={language} isLogin={true} />
      <InviteMeSection language={language} />
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { resetPassword }
)(ResetPassword)
