import React from 'react'
import { Router } from '@reach/router'

import Landing from '../components/Landing'
import Contact from '../components/Contact'
import Signup from '../components/Signup'
import Privacy from '../components/Privacy'
import Terms from '../components/Terms'
import About from '../components/About'
import Login from '../components/Login'
import ForgotPassword from '../components/ForgotPassword'
import ResetPassword from '../components/ResetPassword'
import Deals from '../components/Deals'
import ClipDeals from '../components/ClipDeals'
import MyAccount from '../components/MyAccount'
import CashOut from '../components/CashOut'
import CashOutByCheque from '../components/CashOutByCheque'
import CashOutByEmail from '../components/CashOutByEmail'
import CashOutSuccess from '../components/CashOutSuccess'
import Alerts from '../components/Alerts'

import Layout from '../components/components/Layout'
import RouteWrapper from '../components/components/RouteWrapper'

const App = () => (
  <Layout>
    <Router>
      <Landing path="/app" />
      <Contact path="/app/contact" />
      <Privacy path="/app/privacy" />
      <Terms path="/app/terms" />
      <About path="/app/about" />
      <RouteWrapper path="/app/forgot" component={ForgotPassword} />
      <RouteWrapper path="/app/reset_password" component={ResetPassword} isResetPassword />
      <RouteWrapper path="/app/login" component={Login} />
      <RouteWrapper path="/app/signup" component={Signup} />
      <RouteWrapper path="/app/deals" component={Deals} isPrivate />
      <RouteWrapper path="/app/clipdeals" component={ClipDeals} isPrivate />
      <RouteWrapper path="/app/myaccount" component={MyAccount} isPrivate />
      <RouteWrapper path="/app/cashout" component={CashOut} isPrivate />
      <RouteWrapper path="/app/alerts" component={Alerts} isPrivate />
      <RouteWrapper
        path="/app/cashoutbycheque"
        component={CashOutByCheque}
        isPrivate
      />
      <RouteWrapper
        path="/app/cashoutbyemail"
        component={CashOutByEmail}
        isPrivate
      />
      <RouteWrapper
        path="/app/cashoutsuccess"
        component={CashOutSuccess}
        isPrivate
      />
    </Router>
  </Layout>
)

export default App
