import React from 'react'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'

import localization from '../../constants/localization'

import { getUser, getLanguage } from '../../store/data'
import { HeaderLoginUser } from '../components/Header'
import { cashOutByEmail } from '../../actions/cashout'
import eTransferIcon from '../../assets/images/interac-icon.svg'
import eTransferIconFr from '../../assets/images/e-transfer-icon-fr.png'
import CashOutEmailForm from './CashOutEmailForm'

const LinkBreak = () => (
  <div className="my-8 border-bottom border-gray-6 w-full"></div>
)

const CashOutByEmail = ({
  user,
  language,
  cashOutByEmail: cashOutByEmailAction,
}) => {
  const onSubmit = values => {
    cashOutByEmailAction({ email: values.email })
  }

  const fullName = user.data.user.firstName + ' ' + user.data.user.lastName
  return (
    <div>
      <HeaderLoginUser />
      <div className="flex justify-center p-8">
        <Card
          className="text-center text-base text-gray-3 py-8 w-full  md:w-3/4 lg:1/3 rounded-lg"
          css={css`
            max-width: 31rem;
          `}
        >
          <Card.Title className="flex flex-col text-lg font-medium items-start mx-16 ">
            <div className="flex items-end">
              {localization.get(language, 'cashOutByETransfer')}
              <img
                className="w-1/3 ml-4"
                src={language === 'en' ? eTransferIcon : eTransferIconFr}
                alt="e-Transfer"
              />
            </div>
          </Card.Title>
          <LinkBreak />
          <Card.Body className="flex flex-col ">
            <div className="font-medium self-start mb-6 sm:mx-12">
              {fullName}
            </div>
            <CashOutEmailForm onSubmit={onSubmit} language={language} />
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { cashOutByEmail }
)(CashOutByEmail)
