import React from 'react'
import Downshift from 'downshift'
import { Manager, Popper, Reference } from 'react-popper'
import { connect } from 'react-redux'

import { changeLanguage } from '../../actions/user'
import { getLanguage } from '../../store/data'

const Options = ({
  className,
  highlightedIndex,
  getMenuProps,
  getItemProps,
  options,
}) => {
  return options.length ? (
    <ul className={`${className}`} role="menu" {...getMenuProps()}>
      {options.map((item, index) => (
        <li
          role="menuitem"
          key={index}
          {...getItemProps({
            index,
            item: item.value,
            className:
              highlightedIndex === index
                ? 'text-left bg-blue-6 p-3 cursor-pointer w-full'
                : 'text-left bg-white p-3 cursor-pointer',
          })}
        >
          {item.option}
        </li>
      ))}
    </ul>
  ) : null
}

const LanguageDropdown = ({
  input,
  language,
  className = '',
  changeLanguage: changeLanguageAction,
  ...props
}) => (
  <Downshift
    onSelect={value => {
      changeLanguageAction(value)
    }}
    onChange={input.onChange}
  >
    {({
      getToggleButtonProps,
      getItemProps,
      getInputProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex,
    }) => {
      const toggleButtonProps = getToggleButtonProps({
        ...props,
        className: 'focus:outline-none appearance-none text-left ' + className,
      })

      const selectedOption = props.options.find(
        option => option.value === getInputProps().value
      )

      let initialSelectedItem = language === 'en' ? 'English' : 'Français'

      // style the  button
      return (
        <div>
          <Manager>
            <Reference>
              {({ ref: popperTargetRef }) => {
                return (
                  <div
                    ref={popperTargetRef}
                    className={`form-field flex flex-col my-4 border border-brown-1 rounded ${className}`}
                  >
                    <button {...toggleButtonProps}>
                      <span className="text-left text-sm mt-2 ml-4 rounded">
                        {props.placeholder}
                      </span>
                      <div className="form-input focus:outline-none w-full px-4 py-2 -mt-2 text-sm text-black rounded">
                        {selectedOption && 'option' in selectedOption ? (
                          selectedOption.option
                        ) : (
                          <span>{initialSelectedItem}</span>
                        )}
                      </div>
                    </button>
                  </div>
                )
              }}
            </Reference>
            {isOpen && (
              <Popper
                placement="bottom"
                modifiers={{
                  setWidth: {
                    enabled: true,
                    order: 840,
                    fn(data) {
                      //@ts-ignore
                      data.offsets.popper.right = data.offsets.reference.right
                      data.offsets.popper.left = data.offsets.reference.left
                      const width = Math.round(data.offsets.reference.width)
                      data.offsets.popper.width = width
                      data.styles.width = width.toString() + 'px'

                      return data
                    },
                  },
                }}
              >
                {({ ref, style, placement }) => {
                  return (
                    <div
                      className="z-10 border border-brown-1 bg-white rounded shadow text-gray-2 "
                      ref={ref}
                      style={{
                        ...style,
                      }}
                      data-placement={placement}
                    >
                      <Options
                        options={props.options}
                        highlightedIndex={highlightedIndex}
                        inputValue={inputValue}
                        getMenuProps={getMenuProps}
                        getItemProps={getItemProps}
                        changeLanguageAction={changeLanguageAction}
                      />
                    </div>
                  )
                }}
              </Popper>
            )}
          </Manager>
        </div>
      )
    }}
  </Downshift>
)

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { changeLanguage }
)(LanguageDropdown)
