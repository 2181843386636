import React, { useState } from 'react'
import { connect } from 'react-redux'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import canada from 'date-fns/locale/fr-CA'
import usa from 'date-fns/locale/en-US'

import moment from 'moment'
import { getLanguage } from '../../store/data'

const CustomeDatePicker = ({
  innerLabel = '',
  className = '',
  name,
  input,
  language,
  ...fieldProps
}) => {
  const inputDate = moment(input.value, 'DD/MM/YYYY').format('MM/DD/YYYY');
  const [selectedDate, setSelectedDate] = useState('');

  return (
    <div
      className={`form-field flex flex-col my-4 border border-brown-1 rounded relative w-full ${className}`}
    >
      {innerLabel.length > 0 && (
        <label
          htmlFor={name}
          className="text-left text-sm text-gray-10 mt-2 ml-4"
        // {...fieldProps}
        >
          {innerLabel}
        </label>
      )}
      <DatePicker
        className="focus:outline-none form-input w-full px-4 pb-1 -mt-2 text-sm text-black rounded border-0"
        onChange={date => {
          setSelectedDate(date.toString())
          input.onChange(date.toLocaleDateString('en-GB'))
        }}
        selected={selectedDate === '' ? Date.parse(inputDate) : Date.parse(selectedDate)}
        dateFormat={language === 'fr' ? 'd MMM yyyy' : 'MMM do, yyyy'}
        locale={language === 'fr' ? canada : usa}
        showYearDropdown
        yearDropdownItemNumber={50}
        dateFormatCalendar="MMMM"
        scrollableYearDropdown
        {...fieldProps}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state)
})

export default connect(mapStateToProps)(CustomeDatePicker)