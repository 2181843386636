import React from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'

import { getUser } from '../../../store/data'
import { isUserLoggedIn } from '../../../utils/user'
import ResetPassword from '../../../components/ResetPassword'

const RouteWrapper = ({
  component: Component,
  isPrivate = false,
  isLoggedIn,
  isResetPassword = false,
  ...rest
}) => {
  // private route but user is not logged in
  if (isPrivate && !isLoggedIn) {
    navigate(`/app/login`)
    return null
  }

  // not a private route but user is logged in except Reset password
  if (!isPrivate && !isResetPassword && isLoggedIn) {
    navigate(`/app/deals`)
    return null
  }

  return <Component {...rest} />
}

const mapStateToProps = state => {
  return {
    isLoggedIn: isUserLoggedIn(getUser(state)),
  }
}

export default connect(
  mapStateToProps,
  {}
)(RouteWrapper)
