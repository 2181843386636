import React from 'react'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { Global, css } from '@emotion/core'

import { getDeals, getUser, getLanguage } from '../../store/data'
import { changeModalHowItWorks } from '../../actions/user'
import { getLatestDeals } from '../../actions/deals'
import { getMe } from '../../actions/user'
import { HeaderLoginUser } from '../components/Header'
import ModalHowItWorks from '../components/ModalHowItWorks'

import DealItems from './DealItems'

const Deals = ({
  user,
  getLatestDeals: getLatestDealsAction,
  changeModalHowItWorks: changeModalHowItWorksAction,
}) => {
  useEffect(() => {
    getLatestDealsAction()
  }, [])

  return (
    <div>
      <Global
        styles={css`
          body {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}
      />
      <HeaderLoginUser />

      {!user.modalHowItWorksShown && (
        <ModalHowItWorks
          show={true}
          onHide={() => changeModalHowItWorksAction()}
        />
      )}

      <DealItems />
    </div>
  )
}

const mapStateToProps = state => ({
  deals: getDeals(state),
  user: getUser(state),
  language: getLanguage(state),
})

export default connect(mapStateToProps, {
  getLatestDeals,
  getMe,
  changeModalHowItWorks,
})(Deals)
