import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import localization from '../../constants/localization'
import currencyExchange from '../../utils/currencyExchange'
import { getDeals, getUser, getLanguage } from '../../store/data'
import { getMe, unClipOffer } from '../../actions/user'
import whiteLogo from '../../assets/images/white-logo-no-text.svg'

import clipBlueIcon from '../../assets/images/clipped-blue-icon.svg'

import { HeaderLoginUser } from '../components/Header'
import { RoundButton } from '../components/Button'

const ClipDeal = ({
  deal,
  language,
  onClick,
  getMeAction,
  unClipOfferAction,
  ...props
}) => {
  const _ClickOffer = async e => {
    const offerId = deal._id
    try {
      await unClipOfferAction({ offerId })
      await getMeAction()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Card
      className="flex flex-row w-full rounded-lg p-1"
      css={css`
        height: 8rem;
        max-width: 42rem;
      `}
    >
      <Col
        xs="2"
        className="flex flex-col justify-center items-center overflow-hidden"
        css={css`
          min-width: 6rem;
        `}
      >
        <div className="pic-wrapper w-28 w-full ">
          <img
            src={deal.products[0].productMediaUrl}
            alt="deal"
            className="w-full"
          />
        </div>
      </Col>

      <Col className="flex flex-col justify-center">
        <div className="my-2">{language === 'fr' ? deal.titleFr : deal.title}</div>
        <div className="text-green-1 font-medium">
          {currencyExchange(language, deal.cashBack)}
        </div>
      </Col>
      <Col xs="2" className="flex justify-around items-center">
        <RoundButton key={deal._id} onClick={_ClickOffer}>
          <img
            key={deal._id}
            css={css`
              position: absolute;
              width: 0.9rem;
              left: 0.3rem;
              top: 0.3rem;
            `}
            src={clipBlueIcon}
            alt="clipped"
          />
        </RoundButton>
      </Col>
    </Card>
  )
}

const ClipDeals = ({
  user,
  language,
  deals,
  getMe: getMeAction,
  unClipOffer: unClipOfferAction,
}) => {
  useEffect(() => {
    getMeAction()
  }, [])

  const { clippedOffers } = user.data.user

  const filteredOffers = deals.data.filter(e => clippedOffers.includes(e._id))

  return (
    <div>
      <Global
        styles={css`
          body {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}
      />
      <HeaderLoginUser />

      <div className="card-container bg-gray-7 flex flex-col py-32 justify-center">
        <Row noGutters={true}>
          <Col
            className="flex justify-center"
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
          >
            <Card
              css={css`
                max-width: 42rem;
              `}
              className="bg-blue-1 flex h-32 flex justify-center items-center px-4 my-4 rounded-lg sm:flex-row md:h-20 md:justify-between md:px-8"
            >
              <img src={whiteLogo} alt="logo" className="w-6" />
              <center className="text-white text-base px-4 ">
                {localization.get(language, 'clipSpan')}
              </center>
            </Card>
          </Col>
        </Row>

        {filteredOffers.map(deal => (
          <Row key={deal._id} className="my-4 " noGutters={true}>
            <Col
              className="flex justify-center"
              key={deal._id}
              xs={{ span: 10, offset: 1 }}
              md={{ span: 8, offset: 2 }}
            >
              <ClipDeal
                language={language}
                getMeAction={getMeAction}
                unClipOfferAction={unClipOfferAction}
                deal={deal}
                key={deal._id}
              />
            </Col>
          </Row>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  deals: getDeals(state),
  user: getUser(state),
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { unClipOffer, getMe }
)(ClipDeals)
