import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { css } from '@emotion/core'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'
import ReactPlayer from 'react-player'

import currencyExchange from '../../../utils/currencyExchange'
import localization from '../../../constants/localization'
import { getLanguage, getUser, getDeals } from '../../../store/data'
import { getMe, clipOffer, unClipOffer } from '../../../actions/user'
import { isDealClipped } from '../../../utils/user'
import { LinearButton } from '../Button'
import starIcon from '../../../assets/images/star-icon.svg'
import clipWhiteIcon from '../../../assets/images/clipped-white-icon.svg'

import twitterIcon from '../../../assets/images/twitter-blue.svg'
import facebookIcon from '../../../assets/images/facebook-blue.svg'
import emailIcon from '../../../assets/images/emai.svg'

const CustomDialog = React.forwardRef((props, ref) => (
  <Row className="overflow-scroll" noGutters={true}>
    <Col>
      <div
        {...props}
        ref={ref}
        className="text-white rounded-lg px-8"
        css={css`
          background: white;
          border: 1px solid #2f3941;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          max-width: 31rem;
          font-size: 0.9rem;
        `}
      >
        {props.children}
      </div>
    </Col>
  </Row>
))

const ModalShowDealDetails = ({
  user,
  deal,
  language,
  selectedDeal: thisDeal,
  clipOffer: clipOfferAction,
  unClipOffer: unClipOfferAction,
  getMe: getMeAction,
  ownProps,
  ...props
}) => {
  const clippedOffers = user.data.user.clippedOffers
  const offerId = thisDeal._id
  const isClipped = isDealClipped(clippedOffers, offerId)

  const _clippedOnClick = async () => {
    try {
      if (isClipped) {
        await unClipOfferAction({ offerId })
      } else {
        await clipOfferAction({ offerId })
      }
      await getMeAction()
    } catch (err) {
      console.log('error', err)
    }
  }

  const shareUrl = "https://www.eclipsa.com"

  const listItems = language => [
    { name: localization.get(language, 'limit'), detail: thisDeal.limit },
    {
      name: localization.get(language, 'size'),
      detail: language === 'en' ? thisDeal.size : thisDeal.sizeFr,
    },
    {
      name: localization.get(language, 'store'),
      detail: language === 'en' ? thisDeal.store : thisDeal.storeFr,
    },
  ]

  const youtubeUrl = language === 'en' ? thisDeal.youtubeUrl : thisDeal.youtubeUrlFr
  const title = language === 'en' ? thisDeal.title : thisDeal.titleFr
  const shareMessageEn = `Hey look at this great cash back offer on ${title} from the Eclipsa app. Download it here`
  const shareMessageFr = `Hé, regardez cette super offre de remise en argent sur ${title} de l'application Eclipsa. Téléchargez-le ici`

  const moreInfo = language => {
    const monthNames =
      language === 'en'
        ? [
          'January.',
          'February.',
          'March.',
          'April.',
          'May.',
          'June.',
          'July.',
          'August.',
          'September.',
          'October.',
          'November.',
          'December.',
        ]
        : [
          'janvier,',
          'février,',
          'mars,',
          'avril,',
          'mai,',
          'juin,',
          'juillet,',
          'aout,',
          'septembre,',
          'octobre,',
          'novembre,',
          'décembre,',
        ]

    const expiryDate = thisDeal.expiry.split('')
    const expiryYear = expiryDate.slice(0, 4).join('')
    const expiryMonthString = expiryDate.slice(4, 6).join('')
    const expiryMonth = parseInt(expiryMonthString)
    const expiryDay = expiryDate.slice(6, 8).join('')
    const mapMonth = monthNames[expiryMonth - 1]
    const dealExpireOn = localization.get(language, 'dealsModalExpire')

    const detailInfo = `${dealExpireOn} ${mapMonth} ${expiryDay} ${expiryYear}`
    const moreInfos = [
      {
        name: localization.get(language, 'about'),
        detail: language === 'en' ? thisDeal.details : thisDeal.detailsFr,
      },
      {
        name: localization.get(language, 'expiry'),
        detail: detailInfo,
      }
    ]
    if (thisDeal.moreInfo || thisDeal.moreInfoFr) {
      moreInfos.push(
        {
          name: localization.get(language, 'moreInfo'),
          detail: language === 'en' ? thisDeal.moreInfo : thisDeal.moreInfoFr
        }
      )
    }
    return moreInfos
  }
  const LinkBreak = ({ className, children }) => (
    <div className={`relative border-bottom border-brown-1 ${className}`}>
      {children}
    </div>
  )

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="flex flex-col justify-center items-center px-4"
      dialogAs={CustomDialog}
    >
      <Modal.Header closeButton className="border-none"></Modal.Header>

      <Modal.Body className="flex flex-col p-0">
        <Row noGutters={true}
          className="upper-container text-gray-5 overflow-hidden my-1"
          css={css`
            min-height: 8rem;
          `}
        >
          <Col
            xs={{ span: 5 }}
            className="left-wrapper flex justify-center items-center"
          >
            <div className="pic-wrapper w-full sm:w-3/5">
              <img src={thisDeal.products[0].productMediaUrl} alt="item pic" />
            </div>
          </Col>
          <Col
            xs={{ span: 7 }}
            className="right-wrapper flex flex-col justify-around "
          >
            <div>
              <div className="text-lg">{title}</div>
              <div className="text-green-1 font-medium">
                {currencyExchange(language, thisDeal.cashBack)}
              </div>
            </div>
            <div>
              <ul
                className="list-disc flex"
                css={css`
                  list-style-position: inside;
                  flex-wrap: wrap;
                `}
              >
                {listItems(language).map((item, index) => {
                  let className = index === listItems(language).length - 1 ? "w-full" : "w-full sm:w-1/2"
                  return (
                    <div className={className} key={item.name}>
                      <li>
                        <span className="font-semibold ">{item.name}</span>
                        {item.detail}
                      </li>
                    </div>
                  )
                })
                }
              </ul>
            </div>
          </Col>
        </Row>

        <div className="text-center m-4">
          <LinkBreak className="h-5 mb-8">
            <div className="bg-white inline">
              <LinearButton
                className="bg-blue-4 h-10 px-8 py-2 border-none rounded-full w-36 "
                onClick={_clippedOnClick}
              >
                <center className="flex justify-around items-center">
                  <img
                    className="w-4 mr-4"
                    src={isClipped ? clipWhiteIcon : starIcon}
                    alt="star"
                  />

                  {isClipped
                    ? localization.get(language, 'clipped')
                    : localization.get(language, 'clipIt')}
                </center>
              </LinearButton>
            </div>
          </LinkBreak>
        </div>
        {youtubeUrl && (
          <div className="rounded-lg overflow-hidden">
            <ReactPlayer
              className="w-full h-48 bg-gray-3 rounded"
              url={youtubeUrl}
              controls
            />
          </div>
        )}
        <div className="lower-container text-gray-1">
          {moreInfo(language).map(info => (
            <div className="mt-4" key={info.name}>
              <div className="font-semibold">{info.name}</div>
              <div className="text-sm text-gray-5 font-light">
                {info.detail}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer className="flex flex-col justify-center border-top-0 text-gray-1 my-6 ">
        <div className="w-full md:w-4/5 text-center mb-8">
          <LinkBreak className="h-3">
            <span className="text-base bg-white px-4">
              {localization.get(language, 'tellYourFriends')}
            </span>
          </LinkBreak>
        </div>

        <div className="flex justify-between items-center w-2/3 md:w-1/3">
          <FacebookShareButton
            url={shareUrl}
            quote={language === 'en' ? `${shareMessageEn} ${shareUrl}` : `${shareMessageFr} ${shareUrl}`}
          >
            <img src={facebookIcon} />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={language === 'en' ? shareMessageEn : shareMessageFr}
          >
            <img src={twitterIcon} alt="twitter" />
          </TwitterShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={'Eclipsa'}
            body={language === 'en' ? shareMessageEn : shareMessageFr}
          >
            <img src={emailIcon} alt="email" />
          </EmailShareButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: getLanguage(state),
    user: getUser(state),
    deal: getDeals(state),
  }
}

export default connect(
  mapStateToProps,
  { getMe, clipOffer, unClipOffer }
)(ModalShowDealDetails)
