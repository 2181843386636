import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { Form } from 'react-final-form'
import FormField from '../components/FormField'
import {
  required,
  validEmail,
  composeValidators,
} from '../components/FormField/validations'
import { CustomButton } from '../components/Button'

import localization from '../../constants/localization'

const ForgotForm = ({ language, onSubmit, message, messageColor }) => {
  const customValidators = (...validators) => val =>
    composeValidators(validators, language, val)
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors, form }) => (
        <form
          onSubmit={async e => {
            try {
              await handleSubmit(e)
              await form.reset()
            } catch (err) {
              console.log(err)
            }
          }}
        >
          <div className="text-lg mb-2 ">
            {localization.get(language, 'forgotPassword')}
          </div>
          <div className="text-sm mb-8">
            {localization.get(language, 'forgotPasswordSpan')}
          </div>
          <FormField
            name="email"
            type="text"
            placeholder={localization.get(language, 'emailAddress')}
            validate={customValidators(required, validEmail)}
            message={message}
            messageColor={messageColor}
          />

          <div className="flex items-center justify-start mt-4">
            <CustomButton
              type="submit"
              disabled={submitting || hasValidationErrors}
              className="bg-blue-2 h-12 px-8 text-base"
              css={css`
                min-width: 8.1rem;
              `}
              content={localization.get(language, 'done')}
            />

            <span className="text-sm text-right">
              <Link className="text-blue-4 ml-8 text-base" to={'/app/login'}>
                {localization.get(language, 'cancel')}
              </Link>
            </span>
          </div>
        </form>
      )}
    />
  )
}
export default ForgotForm
