import React from 'react'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import firebase from 'firebase/app'
import 'firebase/storage'
import { css } from '@emotion/core'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import localization from '../../constants/localization'
import { getUser, getLanguage } from '../../store/data'
import {
  getMe,
  updateProfile,
  changePassword,
  updateProfielPic,
} from '../../actions/user'
import { HeaderLoginUser } from '../components/Header'
import accountAvatar from '../../assets/images/avatar-icon.png'
import { LinearButton } from '../components/Button'
import { ProfileUpdateFrom, PasswordUpdateForm } from './ProfileUpdateForm'
import UpdateProfielPic from './UpdateProfilePic'
import { cashExchange } from '../../utils/currencyExchange'

const TableCell = ({ children }) => (
  <div className="flex flex-col items-center">{children}</div>
)

const CellTitle = ({ children }) => (
  <div className="text-sm text-gray-10 font-medium ">{children}</div>
)

const CellContent = ({ children, className = '', ...props }) => (
  <div className={`text-lg font-light ${className}`}>{children}</div>
)

const LinkBreak = () => <div className="my-8 border-bottom border-gray-6"></div>

const MyAccount = ({
  user,
  language,
  getMe: getMeAction,
  updateProfielPic: updateProfielPicAction,
  updateProfile: updateProfileAction,
  changePassword: changePasswordAction
}) => {
  const [passwordMessage, setPasswordMessage] = useState('')
  const [emailMessage, setEmailMessage] = useState('')
  const [messageColor, setMessageColor] = useState('')

  useEffect(() => {
    getMeAction()
  }, [])
  const fullName = user.data.user.firstName + ' ' + user.data.user.lastName
  const profilePictureUrl = user.data.user.profilePictureUrl
    ? user.data.user.profilePictureUrl
    : accountAvatar

  const _onSubmit = values => {
    const dob = values.dob ? values.dob : ''
    updateProfileAction({
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      dob: dob,
      postalCode: values.postalCode,
      callback: _onUpdateEmailCallback
    })
  }
  const _onSubmitPassword = values => {
    changePasswordAction({ password: values.newPassword, callback: _onUpdatePasswordCallback })
  }

  const _onUpdatePasswordCallback = (message, success) => {
    setPasswordMessage(message)
    setMessageColor(success ? 'success' : 'danger')

    setTimeout(() => {
      setPasswordMessage('')
    }, 2000)
  }

  const _onUpdateEmailCallback = (message, success) => {
    setEmailMessage(message)
    setMessageColor(success ? 'success' : 'danger')

    setTimeout(() => {
      setEmailMessage('')
    }, 2000)
  }

  const handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref('profile-images')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        updateProfielPicAction({ url })
      })
  }

  const handleUploadError = error => {
    console.error(error)
  }

  return (
    <div>
      <HeaderLoginUser />
      <div className="flex justify-center p-8">
        <Card
          className="text-center text-gray-4 py-8 w-full md:w-3/4 lg:1/3"
          css={css`
            max-width: 31rem;
          `}
        >
          <Card.Body className="flex flex-col items-center">
            <div className="text-gray-1 font-medium mb-4">{fullName}</div>
            <div className="w-24 h-24 overflow-hidden my-4 rounded-full">
              <UpdateProfielPic
                handleUploadError={handleUploadError}
                handleUploadSuccess={handleUploadSuccess}
                profilePictureUrl={profilePictureUrl}
              />
            </div>
            <Row noGutters={true}
              css={css`
                width: 100%;
                display: flex;
                margin: 1rem auto;
              `}
            >
              <Col>
                <TableCell>
                  <CellTitle>{localization.get(language, 'pending')}</CellTitle>
                  <CellContent>
                    {cashExchange(language, user.data.user.pending)}
                  </CellContent>
                </TableCell>
              </Col>
              <Col>
                <TableCell>
                  <CellTitle>{localization.get(language, 'balance')}</CellTitle>
                  <CellContent className="text-green-1">
                    {cashExchange(language, user.data.user.balance)}
                  </CellContent>
                </TableCell>
              </Col>

              <Col>
                <TableCell>
                  <CellTitle>
                    {localization.get(language, 'earnings')}
                  </CellTitle>
                  <CellContent>
                    {cashExchange(language, user.data.user.earnings)}
                  </CellContent>
                </TableCell>
              </Col>
            </Row>
            {/* need to add validation that only be able when balance >= 20 */}
            <Link to="/app/cashout">
              <LinearButton disabled={user.data.user.balance < 20 ? true : false}>
                {localization.get(language, 'cashOut')}
              </LinearButton>
            </Link>

            <div className="text-sm mt-6">
              {localization.get(language, 'cashOutMinimum')}
            </div>
          </Card.Body>

          <LinkBreak />

          <div
            className="w-3/4 flex flex-col"
            css={css`
              margin: 1rem auto;
            `}
          >
            <ProfileUpdateFrom
              onSubmit={_onSubmit}
              language={language}
              getMe={user.data.user}
              message={emailMessage}
              messageColor={messageColor}
            />

            <div className="pt-6">
              <PasswordUpdateForm
                onSubmitPassword={_onSubmitPassword}
                language={language}
                getMe={user.data.user}
                message={passwordMessage}
                messageColor={messageColor}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  language: getLanguage(state)
})

export default connect(
  mapStateToProps,
  {
    getMe,
    updateProfielPic,
    updateProfile,
    changePassword,
  }
)(MyAccount)
