import React, { useState } from 'react'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'

import localization from '../../constants/localization'

import { getUser, getLanguage } from '../../store/data'
import { cashOutByCheque } from '../../actions/cashout'
import { HeaderLoginUser } from '../components/Header'

import CashOutForm from './CashOutChequeForm'

const LinkBreak = () => (
  <div className="my-8 border-bottom border-gray-6 w-full"></div>
)

const CashOutByCheque = ({
  user,
  language,
  cashOutByCheque: cashOutByChequeAction,
}) => {
  const fullName = user.data.user.firstName + ' ' + user.data.user.lastName

  const onSubmit = values => {
    cashOutByChequeAction({
      address: values.address,
      city: values.city,
      postalCode: values.postalCode,
      province: values.province,
    })
  }

  return (
    <div>
      <HeaderLoginUser />
      <div className="flex justify-center p-8">
        <Card
          className="text-center text-base text-gray-4 py-8 w-full  md:w-3/4 lg:1/3 rounded-lg"
          css={css`
            max-width: 31rem;
          `}
        >
          <Card.Title className="flex flex-col text-lg font-medium items-start mx-16 ">
            <div>{localization.get(language, 'cashOutByChequeDetail')}</div>
          </Card.Title>
          <LinkBreak />
          <Card.Body className="flex flex-col ">
            <div className="font-medium self-start mb-6 sm:mx-12">
              {fullName}
            </div>
            <CashOutForm language={language} onSubmit={onSubmit} />
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { cashOutByCheque }
)(CashOutByCheque)
