import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import { css, Global } from '@emotion/core'
import { Link } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import localization from '../../constants/localization'
import { HeaderLoginUser } from '../components/Header'
import cashOutSuccessPic from '../../assets/images/cash-back-success.png'
import { getLanguage, getCashouts } from '../../store/data'
import { LinearButton } from '../components/Button'

const CashOutSuccess = ({ language, cashouts }) => {
  const { byEmail } = cashouts.data

  return (
    <div>
      <Global
        styles={css`
          body {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}
      />
      <HeaderLoginUser />
      <div className="flex justify-center p-8">
        <Card
          className="flex  items-center text-gray-3 py-8 w-full md:w-3/4 lg:1/3 rounded-lg"
          css={css`
            max-width: 31rem;
          `}
        >
          <div className="w-1/3">
            <img src={cashOutSuccessPic} alt="cash out" />
          </div>
          <Row noGutters={true}>
            <Col
              xs={{ span: 12 }}
              sm={{ offset: 1, span: 10 }}
              md={{ span: 6, offset: 3 }}
            >
              <center className="text-xl my-4">
                {localization.get(language, 'cashOutSuccess')}
              </center>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ offset: 1, span: 10 }}
              md={{ span: 6, offset: 3 }}
            >
              <center className="my-4 mx-4">
                {byEmail
                  ? localization.get(language, 'cashOutSuccessByEmailMsg')
                  : localization.get(language, 'cashOutSuccessByChequeMsg')}
              </center>
            </Col>
          </Row>
          <Link to="/app/myaccount">
            <LinearButton className="my-4">
              {localization.get(language, 'myAccount')}
            </LinearButton>
          </Link>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  cashouts: getCashouts(state),
})

export default connect(
  mapStateToProps,
  {}
)(CashOutSuccess)
