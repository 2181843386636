import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { css, ThemeContext } from '@emotion/core'

import { getLanguage, getUser } from '../../../store/data'
import localization from '../../../constants/localization'
import { CustomButton } from '../Button'
import starIcon from '../../../assets/images/star-icon.svg'
import cameraIcon from '../../../assets/images/camera-icon-modal.svg'
import cashIcon from '../../../assets/images/cash-icon-modal.svg'
import whiteLogo from '../../../assets/images/white_logo.svg'

const workTips = language => [
  {
    content: localization.get(language, 'chooseYourFavourite'),
    note: '',
    icon: starIcon,
  },
  {
    content: localization.get(language, 'takeReceiptPictureSub'),
    note: '',
    icon: cameraIcon,
  },
  {
    content: localization.get(language, 'getCashBack'),
    note: localization.get(language, 'workIntroNote'),
    icon: cashIcon,
  },
]

const CustomDialog = React.forwardRef((props, ref) => (
  <Row className="overflow-scroll" noGutters={true}>
    <Col>
      <div
        {...props}
        ref={ref}
        className="text-white rounded-lg"
        css={css`
          background: #2f3941;
          border: 1px solid #2f3941;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          max-width: 31rem;
          > .modal-header > button > span {
            color: white;
            font-weight: 400;
          }
        `}
      >
        {props.children}
      </div>
    </Col>
  </Row>
))
const ModalHowItWorks = ({ language, ...props }) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      closeButton
      className="flex flex-col justify-center items-center px-4 "
      dialogAs={CustomDialog}
    >
      <Modal.Header
        closeButton
        className="border-0 flex flex-col-reverse items-center"
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-lg mt-4"
        >
          How It Works
        </Modal.Title>
        <div className="w">
          <img src={whiteLogo} alt="eclipsa" />
        </div>
      </Modal.Header>
      <Modal.Body className="flex flex-col">
        {workTips(language).map((tip, index) => (
          <Row className="" key={tip.icon}>
            <Col className="flex justify-end text-2xl -mr-4" xs={3}>
              {index + 1}
            </Col>
            <Col className="mb-12 font-thin text-base  pr-4">
              {tip.content}
              <div className="text-sm">{tip.note}</div>
            </Col>
            <Col className="mt-2" xs={3}>
              <img src={tip.icon} alt="Star" />
            </Col>
          </Row>
        ))}
        <span
          css={css`
            margin: -1.5rem auto;
            padding: 0 1rem 0 1.5rem;
          `}
          className="text-red-2"
        >
          {localization.get(language, 'dealsExpire')}
        </span>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <CustomButton
          className="bg-blue-4 text-base mt-4 mb-8 h-10 px-8 py-2 border-none rounded-full "
          onClick={props.onHide}
          css={css`
            margin: 1rem auto;
            padding: 1rem;
          `}
          content={localization.get(language, 'startSavingNow')}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state), // only show this for the first-time user
})

export default connect(
  mapStateToProps,
  {}
)(ModalHowItWorks)
