/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'
import { Global, css } from '@emotion/core' // may remvove later
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { getLanguage, getUser } from '../../store/data'
import localization from '../../constants/localization'
import bgImage from '../../assets/images/terms-page-bg.jpg'
import { HeaderDefault, HeaderLoginUser } from '../components/Header'
import { isUserLoggedIn } from '../../utils/user'
import GlobalBg from '../components/GlobalBg'

const Privacy = ({ language, isLoggedIn }) => (
  <div>
    <GlobalBg bgImage={bgImage} />

    {isLoggedIn ? <HeaderLoginUser /> : <HeaderDefault />}
    <Row noGutters={true}>
      <Col
        xs={{ offset: 1, span: 10 }}
        md={{ offset: 1, span: 10 }}
        lg={{ offset: 2, span: 8 }}
        className="sm:mt-10 md:mt-40 mb-32"
      >
        <Card className="py-16 px-12 rounded-lg">
          <Card.Title className="text-blue-5 text-2xl mb-4">
            {localization.get(language, 'privacyTitle')}
          </Card.Title>
          <b>
            <hr style={{ width: '100%' }} />
          </b>
          <div className="mt-8 text-gray-3 text-lg">
            {localization.get(language, 'privacySubtitle')}
          </div>

          <Card.Text
            className="-mt-4 text-gray-3 text-sm"
            dangerouslySetInnerHTML={{
              __html: localization.get(language, 'privacyContent'),
            }}
          />
        </Card>
      </Col>
    </Row>
  </div>
)

const mapStateToProps = state => ({
  language: getLanguage(state),
  isLoggedIn: isUserLoggedIn(getUser(state)),
})

export default connect(
  mapStateToProps,
  {}
)(Privacy)
