import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import FormField from '../components/FormField'
import {
  required,
  minLength,
  validEmail,
  validPostalCode,
  composeValidators,
} from '../components/FormField/validations'
import localization from '../../constants/localization'
import LanguageDropdown from './LanguageDropdown'
import CustomeDatePicker from './CustomeDatePicker'
import { LinearButton, LinearButtonHeightAuto } from '../components/Button'

const Input = ({
  innerLabel = '',
  className = '',
  name,
  input,
  ...fieldProps
}) => {
  return (
    <div
      className={`form-field flex flex-col my-4 border border-brown-1 rounded ${className}`}
    >
      {innerLabel.length > 0 && (
        <label
          htmlFor={name}
          className="text-left text-sm text-gray-10 mt-2 ml-4"
          {...fieldProps}
        >
          {innerLabel}
        </label>
      )}
      <input
        className="focus:outline-none form-input w-full px-4 pb-1 -mt-2 text-sm text-black rounded border-0"
        autoComplete="false"
        onChange={input.onChange}
        value={input.value}
        type={input.type}
        onBlur={input.onBlur}
        {...fieldProps}
      />
    </div>
  )
}

const dropdownOptions = [
  { value: 'en', option: 'English' },
  { value: 'fr', option: 'Français' },
]

const FormTitle = ({ children }) => (
  <div className="text-left text-gray-2 mt-10">{children}</div>
)
const FormLayout = ({ children }) => <div className="-mt-10">{children}</div>

export const ProfileUpdateFrom = ({ language, onSubmit, getMe, message, messageColor, ...props }) => {
  // pass the input value, array of validator and language to the validatorComposer
  const customValidators = (...validators) => {
    // the value is the result of the input of form field
    return val => {
      return composeValidators(validators, language, val)
    }
  }

  return (
    <Form
      initialValues={getMe}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors }) => {
        return (
          <FormLayout>
            <form onSubmit={handleSubmit}>
              <FormTitle>{localization.get(language, 'yourProfile')}</FormTitle>
              <FormField
                component={Input}
                innerLabel={localization.get(language, 'firstName')}
                name="firstName"
                type="text"
                // placeholder={localization.get(language, 'firstName')}
                validate={customValidators(required)}
              />
              <FormField
                component={Input}
                innerLabel={localization.get(language, 'lastName')}
                name="lastName"
                type="text"
                // placeholder={localization.get(language, 'lastName')}
                validate={customValidators(required)}
              />
              <FormField
                component={Input}
                innerLabel={localization.get(language, 'updateYourEmail')}
                name="emailAddress"
                type="text"
                // placeholder={localization.get(language, 'updateYourEmail')}
                validate={customValidators(required, validEmail)}
              />
              <FormField
                component={Input}
                innerLabel={localization.get(language, 'updateYourPostalCode')}
                name="postalCode"
                type="text"
                // placeholder={localization.get(language, 'updateYourPostalCode')}
                validate={customValidators(required, validPostalCode)}
              />
              {/* calendar */}
              <FormField
                component={CustomeDatePicker}
                as="div"
                innerLabel={localization.get(language, 'dateOfBirth')}
                name="dob"
              />

              <FormField
                name="language"
                component={LanguageDropdown}
                options={dropdownOptions}
                placeholder={localization.get(language, 'selectLanguage')}
                message={message}
                messageColor={messageColor}
              />
              <div className="flex justify-center mt-8">
                <LinearButtonHeightAuto
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  className="bg-blue-2 px-8 text-base"
                  css={css`
                    min-width: 8.1rem;
                  `}
                >
                  {localization.get(language, 'updateProfileButton')}
                </LinearButtonHeightAuto>
              </div>
            </form>
          </FormLayout>
        )
      }}
    />
  )
}

export const PasswordUpdateForm = ({
  language,
  onSubmitPassword,
  getMe,
  message,
  messageColor,
  ...props
}) => {
  const customValidators = (...validators) => val =>
    composeValidators(validators, language, val)

  return (
    <Form
      initialValues={getMe}
      onSubmit={onSubmitPassword}
      render={({ form, handleSubmit, submitting, hasValidationErrors }) => {
        return (
          <FormLayout>
            <form
              onSubmit={async e => {
                await handleSubmit(e)
                await form.reset()
              }}
            >
              <FormTitle>
                {localization.get(language, 'updatePassword')}
              </FormTitle>
              <FormField
                name="newPassword"
                type="password"
                placeholder={localization.get(language, 'newPassword')}
                validate={customValidators(required, minLength(8))}
                message={message}
                messageColor={messageColor}
              />
              <div className="flex justify-center mt-8">
                <LinearButtonHeightAuto
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  className="bg-blue-2 px-2 text-sm sm:text-base"
                  css={css`
                    min-width: 8.1rem;
                  `}
                >
                  {localization.get(language, 'updatePasswordButton')}
                </LinearButtonHeightAuto>
              </div>
            </form>
          </FormLayout>
        )
      }}
    />
  )
}
