import React, { useState } from 'react'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { getDeals, getUser, getLanguage } from '../../store/data'
import { changeModalHowItWorks } from '../../actions/user'
import { getLatestDeals } from '../../actions/deals'
import { getMe, clipOffer, unClipOffer } from '../../actions/user'
import localization from '../../constants/localization'

import clipIcon from '../../assets/images/clipped-icon.svg'
import clipBlueIcon from '../../assets/images/clipped-blue-icon.svg'
import { RoundButton } from '../components/Button'
import ModalShowDealDetails from '../components/ModalShowDealDetails'
import ItemCard from './DealCard'
import { parseDate } from '../../utils/date'

const DealItem = ({
  clipOfferAction,
  unClipOfferAction,
  getLatestDealsAction,
  getMeAction,
  clippedOffers,
  user,
  deal,
  onClick,
  ...props
}) => {
  const offerId = deal._id
  const isClipped = clippedOffers.includes(offerId)

  const _clippedOnClick = async e => {
    try {
      if (isClipped) {
        await unClipOfferAction({ offerId })
      } else {
        await clipOfferAction({ offerId })
      }
      await getMeAction()
    } catch (err) {
      console.log('error', err)
    }
  }
  return (
    <Col
      className="my-8 w-1/2 overflow-hidden lg:w-1/3 flex justify-center items-center flex-wrap"
      sm={{ span: 6 }}
      md={{ span: 4 }}
    >
      <ItemCard
        deal={deal}
        isClipped={isClipped}
        onClick={onClick}
        _clippedOnClick={_clippedOnClick}
      />
    </Col>
  )
}

const DealItems = ({
  user,
  deals,
  clipOffer: clipOfferAction,
  unClipOffer: unClipOfferAction,
  getLatestDeals: getLatestDealsAction,
  getMe: getMeAction,
  language
}) => {
  const clippedOffers = user.data.user.clippedOffers
  const appLanguage = language
  deals.data = deals.data.filter(deal => parseDate(deal.expiry) > new Date());

  //pass the id of clicked item to modal of detail
  const [selectedDealId, setSelectedDealId] = useState()

  return (
    <div>
      {/* show and hide modal */}
      {selectedDealId && (
        <ModalShowDealDetails
          show={true}
          onHide={() => setSelectedDealId()}
          selectedDeal={
            selectedDealId &&
            deals.data.find(deal => deal._id === selectedDealId)
          }
        />
      )}

      {/* display all deal items */}
      <div className="card-container bg-gray-7 flex justify-center py-16 px-4">
        <Row
          css={css`
            max-width: 52rem;
          `}
          className="flex flex-wrap -mx-2 overflow-hidden md:mx-4"
        >
          {deals.data.length === 0 ?
            <div className="text-black text-lg mt-2"
              css={css`
              height: 20rem;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
          `}>
              {localization.get(appLanguage, 'noDeal')}
              <br />
              {localization.get(appLanguage, 'noDeal1')}
            </div>
            :
            deals.data.map(deal => (
              <DealItem
                getMeAction={getMeAction}
                clipOfferAction={clipOfferAction}
                unClipOfferAction={unClipOfferAction}
                getLatestDealsAction={getLatestDealsAction}
                clippedOffers={clippedOffers}
                deal={deal}
                key={deal._id}
                onClick={() => setSelectedDealId(deal._id)}
              />
            ))}
        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  deals: getDeals(state),
  user: getUser(state),
  language: getLanguage(state)
})

export default connect(
  mapStateToProps,
  {
    getLatestDeals,
    clipOffer,
    unClipOffer,
    getMe,
    changeModalHowItWorks
  }
)(DealItems)
