import React from 'react'
import firebase from 'firebase/app'
import 'firebase/storage'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'
import { css } from '@emotion/core'
import Image from 'react-bootstrap/Image'

export default ({
  handleUploadError,
  handleUploadSuccess,
  profilePictureUrl,
}) => {
  return (
    <CustomUploadButton
      className="profile-container relative flex flex-col justify-end items-center hover:outline-blue-400 cursor-pointer"
      accept="image/*"
      name="avatar"
      randomizeFilename
      storageRef={firebase.storage().ref('profile-images')}
      onUploadError={handleUploadError}
      onUploadSuccess={handleUploadSuccess}
      css={css`
        &:hover .overlay {
          opacity: 1;
        }
      `}
    >
      <Image src={profilePictureUrl} alt="profile-pic" className="w-24 h-24 " />
      <div
        className="overlay"
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: gray;
        `}
      >
        <div
          className="text"
          css={css`
            color: white;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
          `}
        >
          New Profile
        </div>
      </div>
    </CustomUploadButton>
  )
}
