import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { getLanguage } from '../../store/data'
import LogoWithSpan from '../components/LogoWithSpan'
import SignupForm from './SignupForm'
import bgImage from '../../assets/images/signup-page-bg.webp'
import { registerUser } from '../../actions/user'
import FacebookLoginButton from '../components/FacebookLoginButton'
import HowItWork from '../components/HowItWork'
import InviteMeSection from '../components/InviteMeSection'
import { HeaderDefault } from '../components/Header'
import { LinkBreak } from '../Login/index'
import GlobalBg from '../components/GlobalBg'

const Signup = ({ language, registerUser: registerUserAction }) => {
  const [message, setMessage] = useState('')

  const onSubmit = values => {
    // resgiter user
    registerUserAction({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      postalCode: values.postalCode,
      callback: onRegisterCallback
    })
  }

  const onRegisterCallback = message => {
    setMessage(message)
    setTimeout(() => {
      setMessage('')
    }, 2000)
  }

  return (
    <div className="pt-8">
      {/* global styles */}
      <GlobalBg bgImage={bgImage} />

      <HeaderDefault />

      <LogoWithSpan />

      <Row className="mt-10 mb-16" noGutters={true}>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 3, span: 6 }}
          xl={{ offset: 4, span: 4 }}
          className="bg-white py-12 px-4 sm:px-10 rounded-lg shadow"
        >
          <FacebookLoginButton language={language} />

          {/* line break */}
          <LinkBreak />

          <SignupForm language={language} onSubmit={onSubmit} message={message} messageColor={'danger'} />
        </Col>
      </Row>

      <HowItWork language={language} />
      <InviteMeSection language={language} />
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(
  mapStateToProps,
  { registerUser }
)(Signup)
